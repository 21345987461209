<template>
    <v-container class="mb-4">
        <v-row dense>
            <v-col cols="7">
                <v-snackbar
                v-model="snackbar"
                :multi-line="multiLine"
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
                </v-snackbar>
                <MyCard height="100%">
                    <template v-slot:header>Subscription details</template>
                    <v-container>
                        <v-row dense>
                            <v-col cols="6"><v-subheader class="px-0">Active subscription</v-subheader></v-col>
                            <v-col cols="6"><v-text-field flat solo hide-details readonly class="no-line" :value="organization.tier.name" v-if="loaded" /></v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6"><v-subheader class="px-0">Billing period</v-subheader></v-col>
                            <v-col cols="6"><v-text-field flat solo hide-details readonly class="no-line" :value="organization.billing_period" v-if="loaded" /></v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6"><v-subheader class="px-0">Subscription expires</v-subheader></v-col>
                            <v-col cols="6">
                                <v-text-field flat solo hide-details readonly class="no-line" :value="organization.paid_until | ts_format" v-if="loaded" />
                                <v-text-field flat solo hide-details readonly class="no-line" value="never" v-else />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"><v-subheader class="px-0">Restart, upgrade or cancel subscription, change billing address, view and download invoices</v-subheader></v-col>
                            <v-col cols="6">
                                <v-btn v-if="isStripeCustomer" link @click="manageSubscription">Manage Subscription<v-icon right>mdi-open-in-new</v-icon></v-btn>
                                <v-tooltip top v-else >
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <v-btn link disabled @click="manageSubscription">Manage Subscription<v-icon right>mdi-open-in-new</v-icon></v-btn>
                                        </div>
                                    </template>
                                    <span>Only paying customer can manage their subscriptions.</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-container>
                </MyCard>
            </v-col>
            <v-col cols="5" v-if="!isStripeCustomer">
                <MyCard>
                    <template v-slot:header>Start your free trial</template>
                    <v-container>
                        Billing period:
                        <span>
                            <v-btn
                                rounded
                                :elevation="sub_time_period === 'month' ? 2 : 0"
                                @click="set_sub_time_period('month')"
                                :outlined="sub_time_period === 'month'"
                                :text="sub_time_period !== 'month'"
                            >
                                Month
                            </v-btn>
                            <v-btn
                                rounded
                                :elevation="sub_time_period === 'year' ? 2 : 0"
                                @click="set_sub_time_period('year')"
                                :outlined="sub_time_period === 'year'"
                                :text="sub_time_period !== 'year'"
                            >
                                Year
                            </v-btn>
                        </span>
                    </v-container>
                    <Tier
                        v-for="tier in tiers"
                        :key="tier.guid"
                        :period="sub_time_period"
                        :tier="tier"
                        :colors="colors"
                        @selected="select(tier)"
                    />

                    <v-btn
                        x-large
                        class="my-4"
                        width="400"
                        color="primary"
                        :disabled="selected_tier && selected_tier.name === 'free'"
                        @click="checkout"
                    >Checkout
                    <v-icon right>mdi-open-in-new</v-icon></v-btn>
                </MyCard>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="7">
                <WidgetTier />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
import { ref, reactive, onMounted, getCurrentInstance } from "@vue/composition-api";

import MyCard from "@/components/MyCard.vue";
import Tier from "@/components/Tier.vue";
import WidgetTier from "@/components/WidgetTier.vue";
import { useStore } from "@/store/index.js";

export default {
    props: {},
    components: {
        MyCard,
        Tier,
        WidgetTier,
    },
    setup() {
        const { emit } = getCurrentInstance();
        const store = useStore();
        const snackbar = ref(false);
        const text = ref("");

        // TODO make fully dynamic/based on DB data
        const colors = ref({
            free: "",
            basic: "",
            pro: "",
        });

        const organization = reactive({});
        const loaded = ref(false);

        const selected_tier = ref({});
        const sub_time_period = ref("month");

        const tiers = ref([]);
        const messages = ref({});

        function set_sub_time_period(new_period) {
            sub_time_period.value = new_period;
        }

        function checkout() {
            const postData = {
                tier_guid: selected_tier.value.guid,
                period: sub_time_period.value,
            };

            axios
                .post("/stripe/create-checkout-session", postData)
                .then((response) => {
                    if (response.data.success) {
                        window.location.href = response.data.redirect;
                    } else {
                        text.value = "Cannot check-out at this moment. Please try again later.";
                        snackbar.value = true;
                    }
                })
                .catch(() => {
                    text.value = "Cannot check-out at this moment. Please try again later.";
                    snackbar.value = true;
                });
        }

        function manageSubscription() {
            axios
                .post("/stripe/create-customer-portal-session")
                .then((response) => {
                    if (response.data.success) {
                        window.location.href = response.data.redirect;
                    } else {
                        text.value = "Cannot check-out at this moment. Please try again later.";
                        snackbar.value = true;
                    }
                })
                .catch(() => {
                    text.value = "Cannot communicate with Stripe at this moment. Please try again later.";
                    snackbar.value = true;
                });
        }

        function select(val) {
            /*
              visually select picked tier by manipulating `colors`
              `val` = tier object, not proxy
            */
            selected_tier.value = val;
            for (var i in tiers.value) {
                colors.value[tiers.value[i].name] = tiers.value[i].name == val.name ? "primary" : "";
            }
        }

        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Subscription",
                    disabled: false,
                    href: "/",
                },
            ]);

            axios.get("/api/v1/tiers").then((response) => {
                tiers.value = response.data.filter((x) => x.stripe_enabled);
                if (organization.tier) {
                    select(organization.tier);
                }
            });

            axios.get("/api/v1/billing_info").then((response) => {
                Object.assign(organization, response.data);
                loaded.value = true;

                colors.value[selected_tier.name] = "primary";
                sub_time_period.value = organization.billing_period;

                if (tiers.value.length > 0) {
                    select(organization.tier);
                }
            });
        });

        return {
            snackbar,
            text,
            colors,
            organization,
            selected_tier,
            sub_time_period,
            tiers,    // remove altogether and populate _paidTiers by filtered values
            messages,
            loaded,
            isStripeCustomer: store.state.session.isStripeCustomer,
            checkout,
            manageSubscription,
            select,
            set_sub_time_period,
        };
    },
};
</script>
