<template>
    <MyCard :width="width">
        <template v-slot:header>
            <div class="float-left">Subscription Plan Usage</div>
            <v-spacer />
        </template>
        <v-list>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="float-left">Profiles</div>
                        <div class="float-right">{{ tierInfo.used_profiles }}/{{ tierInfo.max_profiles }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-progress-linear :value="(tierInfo.used_profiles * 100) / tierInfo.max_profiles" rounded />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="float-left">BGP peers</div>
                        <div class="float-right">{{ tierInfo.used_bgp_peers }}/{{ tierInfo.max_bgp_peers }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-progress-linear :value="(tierInfo.used_bgp_peers * 100) / tierInfo.max_bgp_peers" rounded />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="float-left">Nodes per peer</div>
                        <div class="float-right">{{ tierInfo.used_nodes_per_peer }}/{{ tierInfo.max_nodes_per_peer }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-progress-linear :value="(tierInfo.used_nodes_per_peer * 100) / tierInfo.max_nodes_per_peer" rounded />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="float-left">Filters per Profile</div>
                        <div class="float-right">{{ tierInfo.used_filters_per_profile }}/{{ tierInfo.max_filters_per_profile }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-progress-linear :value="(tierInfo.used_filters_per_profile * 100) / tierInfo.max_filters_per_profile" rounded />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </MyCard>
</template>

<script>
import { ref } from "@vue/composition-api";
import MyCard from "@/components/MyCard.vue";
import axios from "axios";

export default {
    props: {
        width: String,
    },
    components: {
        MyCard,
    },
    setup() {
        const tierInfo = ref({});
        const isLoaded = ref(false);

        axios.get("/api/v1/tier_info").then((response) => {
            isLoaded.value = true;
            tierInfo.value = response.data;
        });

        return {
            tierInfo,
            isLoaded,
        };
    },
};
</script>
