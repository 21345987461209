<template>
    <v-card
        class="horizontal rounded-lg pa-2"
        raised
        width="400"
        min-height="100"
        max-height="100"
        :elevation="elevation"
        @mouseover="elevation = 1"
        @mouseleave="elevation = 0"
        :color="colors[tier.name]"
        @click="$emit('selected', tier)"
        rounded
    >
        <div class="text-h4" align="center">{{ tier.name }}</div>
        <div class="text-h4" align="center">
            <span v-if="tier.currency_prefix" class="text-h5">{{ tier.currency_symbol }}</span>
            <span v-if="period === 'month'">{{ tier.price_month }}</span>
            <span v-if="period === 'year'">{{ tier.price_year }}</span>
            <span v-if="!tier.currency_prefix">{{ tier.currency_symbol }}</span>
            <span v-if="period === 'month'" class="text-h6">/month</span>
            <span v-if="period === 'year'" class="text-h6">/year</span>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "Tier",
    props: ["tier", "period", "colors"],

    setup() {
        return {
            elevation: 0,
        };
    },
    emits: ["selected"],
};
</script>

<style scoped>
.horizontal {
    display: inline-block;
}

.container {
    flex-direction: column;
    display: flex;
    padding: 0;
    padding-top: 20px;
}

.centered {
    margin: auto;
    /* width: 100%; */
    border: 5px solid #ffff00;
    /* padding: 10px; */
}
.center {
    text-align: center;
}
</style>
